import { Canvas } from "@react-three/fiber";
import React from "react";
// import "./styleee.css";
import ModelViewer from "../Page/ModelViewer";

export const AntThree = () => {
    return (
        <div>
            <div>
                <div>
                    <body>
                        <header className="headContainer">
                            <div className="headAligner">
                                <div className="headLogo">Ant Search</div>
                                <div className="headNav">
                                    <ul className="ulElement">Home</ul>
                                    <ul className="ulElement">About</ul>
                                    <ul className="ulElement">Support</ul>
                                    <ul className="ulElement">Contact</ul>
                                </div>
                            </div>
                        </header>
                        <div class="container">
                            <div class="search-container">
                                <input
                                    type="text"
                                    id="movie-name"
                                    placeholder="Enter movie name here..."
                                    value="search ant movie ..."
                                />
                                <button id="search-btn">Search</button>
                            </div>
                            <Canvas style={{ width: "600px", height: "400px" }} camera={{ position: [0, 2, 5] }}>
                                <ModelViewer />
                            </Canvas>
                        </div>
                    </body>
                </div>
            </div>
        </div>
    );
};

export default AntThree;
