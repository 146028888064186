import React from "react";
import logofooter from "../Images/logoNew.svg";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { BsTelephoneFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { ImLinkedin } from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
    const location = useLocation();

    if (location.pathname === "/login") {
        return null;
    }
    return (
        <>
            <footer className="footer-sec bg-[#19292E] w-[100%] ">
                <div className="flex justify-around  flex-wrap gap-4 text-white py-5 footer_custom">
                    <div className="footerlogo">
                        <img src={logofooter} className="w-[180px] h-[105px]" alt="" />
                    </div>

                    <div className="text-white flex flex-col gap-3 mt-[1.5em] footer_content">
                        <h4 className="text-white text-[16px] mb-[0.5em] font-[500] uppercase">NAVIGATION</h4>
                        <p className="text-[14px] text-gray-300  hover:text-[#21C8ED] cursor-pointer">
                            <Link to="/">Home</Link>
                        </p>
                        <p className="text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                            <Link to="/course">Courses</Link>
                        </p>
                        <p className="text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                            <Link to="/placement"> Placements </Link>
                        </p>
                        <p className="text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                            <Link to="/hirefrom"> Hire from Emergio </Link>
                        </p>
                        <p className="text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                            <Link to="/entertainment"> Entertainment </Link>
                        </p>
                        <p className="text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                            <Link to="/about">About Us </Link>
                        </p>
                        <p className="text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                            <Link to="/career">Career </Link>
                        </p>
                        {/* <p className="text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                            <Link to="/ant">Ant </Link>
                        </p> */}
                    </div>
                    <div className="text-white flex flex-col gap-3 mt-[1.5em] footer_content">
                        <h4 className="text-white text-[16px] mb-[0.5em] font-[500] uppercase">CONTACT US</h4>
                        <div className="flex gap-3">
                            <span className="text-[14px] text-gray-300 mt-1">
                                <FaLocationDot />
                            </span>

                            <p className="text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                                3rd Floor, Cheryl Hill Park, <br />
                                Seaport - Airport Rd,
                                <br />
                                Vallathol Padi, Vidya Nagar Colony,
                                <br />
                                Thrikkakara, Kakkanad, <br />
                                Kochi, Kerala 682021
                            </p>
                        </div>
                        <div className="flex gap-3 items-center">
                            <span className="text-[14px] text-gray-300">
                                <BsTelephoneFill />
                            </span>
                            <div className="text-[14px] text-gray-300">
                                <p className="hover:text-[#21C8ED] cursor-pointer">
                                    <a href="tel:+917594088811">+91 7594088811</a>
                                </p>
                            </div>
                        </div>
                        <div className="flex gap-3 items-center">
                            <span className="text-[14px] text-gray-300">
                                <IoMail />
                            </span>
                            <p className=" cursor-pointer">
                                <a href="mailto:hr@emergiotech.com" className="hover:text-[#21C8ED] text-gray-300">
                                    {" "}
                                    hr@emergiotech.com
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="text-white flex flex-col gap-3 mt-[1.5em] footer_content">
                        <h4 className="text-white text-[16px] mb-[0.5em] font-[500] uppercase">About Emergio Games</h4>
                        <p className=" text-[14px] text-gray-300 hover:text-[#21C8ED] cursor-pointer">
                            Emergio Games Pvt Ltd, headquartered <br /> in Kochi, Kerala, is India's premier game <br />{" "}
                            development company. With years of <br /> experience, Emergio not only excels in <br /> crafting
                            captivating games but also <br />
                            offers high-quality training in lucrative <br /> IT domains.
                        </p>
                        <div className="flex justify-center gap-5  mt-5">
                            <a href="https://api.whatsapp.com/send?phone=7594088816">
                                {" "}
                                <IoLogoWhatsapp className="hover:text-[#21C8ED] transition-all text-[28px]" />
                            </a>
                            <a href="https://www.linkedin.com/company/emergio-games-official/posts/?feedView=all">
                                {" "}
                                <ImLinkedin className="hover:text-[#21C8ED] transition-all text-[27px]" />
                            </a>
                            <a href="https://www.instagram.com/emergio_games?igsh=dGVpenBzNG9lOWpz">
                                {" "}
                                <AiFillInstagram className="hover:text-[#21C8ED] transition-all text-[28px]" />
                            </a>
                            <a href="https://www.youtube.com/@emergioedutech">
                                <IoLogoYoutube className="hover:text-[#21C8ED] transition-all text-[28px]" />
                            </a>
                        </div>
                    </div>
                    <hr className="w-[80%] mt-5 text-gray-700" />
                    <p className=" mt-3 text-[14px]">© Copyright 2024, All Rights Reserved by Emergio Games</p>
                </div>
            </footer>
        </>
    );
};

export default Footer;
