import { OrbitControls } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import ant from "./antlownew.glb";

const ModelViewer = () => {
    const gltf = useLoader(GLTFLoader, ant);
    const ref = useRef();
    useFrame(() => {
        ref.current.rotation.y += 0.002;
    });
    return (
        <>
            <spotLight position={[5, 10, 7.5]} />
            <spotLight position={[-3, 10, -7.5]} />
            <ambientLight intensity={1.5} />
            <pointLight position={[10, 10, 10]} />
            <OrbitControls />
            <primitive object={gltf.scene} ref={ref} />
        </>
    );
};

export default ModelViewer;
