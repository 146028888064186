import React, { useState } from "react";
import circle from "../Images/circle.png";
import mission from "../Images/mission.png";
import aboutimg from "../Images/about.png";
import halfcircle from "../Images/halfcircle.png";
import "./AboutPage.css";
import axios from "axios";
class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
    };
  }
  componentDidMount() {
    let data;
    axios
      .get("https://www.emergiogames.com/emergio/team", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        data = res.data;
        this.setState({
          teams: data,
        });
      })
      .catch((err) => {});
  }
  render() {
    return (
      <>
        <main className="bg-black md:pt-0 pt-[4rem]">
          <section>
            <div className="relative">
              <img
                className="w-[320px] h-[300px] left-[0%] ml-[-15rem]"
                src={circle}
                alt=""
              />
            </div>
            <div className="w-[100%] mt-[-4rem] flex justify-center">
              <div className="flex flex-wrap justify-center sm:justify-between  w-[88%] mt-[-12rem] sm:mt-[2rem] ">
                <div className="  flex flex-col flex-wrap w-[90%] lg:w-[40%]">
                  <h2 className="text-white text-[39px] md:text-[54px] font-[700]">
                    About Us
                  </h2>
                  <p className="text-white text-[13px] sm:text-[16px] mt-3">
                    Emergio Games Pvt Ltd, headquartered in Kochi, Kerala, is
                    India's premier game development company. With years of
                    experience, Emergio not only excels in crafting captivating
                    games but also offers high-quality training in lucrative IT
                    domains. Our programs ensure practical skills and 100% job
                    placement assurance, making us the top choice for aspiring
                    developers and IT professionals across the nation. Join
                    Emergio to unleash your potential and thrive in the dynamic
                    world of gaming and IT.
                  </p>
                </div>

                <img className="lg:mt-[-3rem] w-[50%]" src={aboutimg} alt=""     loading="lazy"/>
              </div>
            </div>
            <div className="w-[100%] flex flex-col items-center justify-center">
              <hr className="bg-white w-[90%] sm:mt-[5rem]" />

              <div className="flex justify-center flex-wrap w-[88%] mt-[6rem] ">
                <div>
                  {" "}
                  <img
                    className="w-[100%] h-[300px] sm:w-[630px] sm:h-[396px] mt-[-4rem] transition-transform duration-300 transform hover:scale-105"
                    src={mission}
                    alt=""
                  />
                </div>
                <div className=" flex flex-col flex-wrap w-[90%] lg:w-[40%]">
                  <h2 className="text-white text-[39px] md:text-[54px] font-[700]">
                    Our Mission
                  </h2>
                  <p className="text-white text-[16px] sm:mb-0 mb-[2rem] mt-3">
                    "Who we are Being one of the leading institutes with a vast
                    experience of many more years in game development, we offer
                    the best talents to this industry who successfully complete
                    our well-structured game development courses every
                    year.Unity is the most popular game engine which widely used
                    in the industry and our training program makes the candidate
                    capable of understanding the workflow in detail thereby
                    creating exciting games."
                  </p>
                </div>
              </div>
            </div>

            {/* our team */}

            <div className="team w-[100%] ">
              <h2 className="pl-[2.5em] pt-[3rem] text-[40px] sm:text-[54px] font-[700] text-white">
                Meet Our Team
              </h2>
              <div className="flex justify-center">
                <img
                  className="sm:mt-[-8rem] mt-[-10rem] w-[130px] h-[80px] sm:w-auto sm:h-auto"
                  src={halfcircle}
                  alt=""
                />
              </div>

              <div className="w-[100%] flex flex-col justify-center items-center">
                <div className="w-[90%] my-[1rem] flex justify-center gap-16 flex-wrap">
                  {this.state.teams.map((data, id) => (
                    <div className="flex flex-wrap mb-3">
                      <div className="flex flex-col justify-center items-center">
                        <img
                          className="transition-transform duration-300 transform hover:scale-105 h-[15em] w-[15em] rounded-full"
                          src={`https://www.emergiogames.com${data.image}`}
                          alt=""
                          loading="lazy"
                        />
                        <h4 className="mt-3 text-white text-[22px] font-[600] uppercase">
                          {data.name}
                        </h4>
                        <h5 className="text-[18px] text-[#21C8ED] font-[600] capitalize">
                          {data.position}
                        </h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-end ml-[8rem] float-end mt-[-45rem]">
                <img
                  className="sm:mt-[-8rem] rotate-90 mt-[-10rem] w-[130px] h-[80px] "
                  src={halfcircle}
                  alt=""
                />
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
export default AboutPage;
