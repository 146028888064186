import React, { useState } from "react";
import axios from "axios";
import circle from "../Images/circle.png";
import entertinmentimg from '../Images/ENTERTAINMENT1.png'
class EntertainmentPage extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    game: [],
    showModal: true,
  }
  handleModalClose = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
    let data;
    axios.get('https://www.emergiogames.com/emergio/game', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        data = res.data;
        this.setState({
          game: data
        });
      })
      .catch(err => { })
  }
  render() {
    return (
      <>
        <main className="bg-black ">
          <section>
            <div className="relative">
              <img
                className="w-[320px] h-[200px] left-[0%] ml-[-15rem]"
                src={circle}
                alt=""
                loading="lazy"
              />
            </div>
            <div className="w-[100%] mt-[-10rem] sm:mt-[-4rem] flex justify-center">
              <div className="flex flex-wrap justify-center sm:justify-between  w-[88%] mt-[-3rem] sm:mt-[2rem] ">
                <div className="  flex flex-col flex-wrap w-[90%] lg:w-[50%] justify-center ">
                  <h2 className="text-white text-[39px] md:text-[54px] font-[700]">
                    Entertainment
                  </h2>
                  <p className="text-white text-[13px] sm:text-[17px] mt-2">
                    Calling all adventurers (or chill couch potatoes)! Dive into the world of entertainments where your next epic quest awaits. Whether you crave heart-pounding action or a relaxing escape, this page has something for everyone. So, what are you waiting for?  Click the button and get ready to embark on an unforgettable journey!
                  </p>
                </div>
                <div className="text-white ">
                  <img  src={entertinmentimg} className="sm:w-[450px] sm:h-[450px]" alt=""     loading="lazy"/>
                </div>
              </div>
            </div>
            <div className=" gap-3 flex flex-wrap justify-evenly mt-[1rem] sm:mt-[6rem] sm:pb-[1em]  mr-[2em] sm:mr-[0]">
              {this.state.game.map((data, index) => (
                <div key={index}>
                  <div className="ent-card-slider relative h-[15em] sm:h-[420px] sm:w-[420px] w-[15em] mb-[1em] mt-[0.5em] sm:mt-[1rem]">
                    <img
                      className=" w-[100%] sm:h-[415px] h-[14.9em] rounded-lg"
                      src={`https://www.emergiogames.com${data.image}`}
                      alt=""
                    />
                    <a href={data.link}><button className="w-[177px] h-[66px] text-white absolute sm:top-[50%] top-[40%] left-[12%] sm:left-[30%]">
                      Play Now
                    </button></a>

                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      </>
    );
  };
}
export default EntertainmentPage;
