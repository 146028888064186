import React, { useState } from "react";
import circle from "../Images/circle.png";
import placementImg from '../Images/PLACEMENT1.png'
import "./PlacementPage.css";
import axios from "axios";
class PlacementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placements: []
    };
  }
  componentDidMount() {
    let data;
    axios.get('https://www.emergiogames.com/emergio/placement', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        data = res.data;
        this.setState({
          placements: data
        });
      })
      .catch(err => { })
  }
  render() {
    return (
      <>
        <main className="bg-black md:pt-0 pt-[4rem]">
          <section>
            <div className="relative">
              <img
                className="w-[320px] h-[200px] left-[0%] ml-[-15rem]"
                src={circle}
                alt=""
                    loading="lazy"
              />
            </div>
            <div className="w-[100%] mt-[-4rem]  flex justify-center ">
              <div className="flex flex-wrap justify-center sm:justify-between  w-[88%] mt-[-12rem]  sm:mt-[2rem]  ">
                <div className="flex flex-col items-center justify-center flex-wrap w-[90%] lg:w-[50%]">
                  <div className="">
                  <h2 className="text-white text-[39px] md:text-[54px] font-[700]">
                    Placements
                  </h2>
                  <p className="text-white text-[17px] sm:text-[17px] mt-2">
                    Imagine launching your career like a rocket – placements are your fuel. These aren't just internships; they're springboards into real-world experience. Dive into a dynamic company, tackle meaningful projects, and gain the skills that make you shine. Placements are your chance to network with inspiring professionals, discover your passions, and land your dream job before graduation. It's like a sneak peek into your future, and it's waiting to be ignited. Are you ready to blast off?
                  </p>
                  </div>
                  
                </div>
                <div className="text-white flex justify-center items-center">
                  <img  src={placementImg} className="sm:w-[450px] sm:h-[450px]" alt=""     loading="lazy" />
                </div>
              </div>
            </div>

            <div className=" flex flex-wrap justify-center items-center align-middle ml-4 mt-[2em] pb-[4em]">
              {this.state.placements.map((detail, id) => ((
                <img src={`https://www.emergiogames.com${detail.image}`} className="sm:w-[50%] w-[100%]" alt="image" />
              )))}
            </div>
          </section>
        </main>
      </>
    );
  };
}
export default PlacementPage;
