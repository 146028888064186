import React, { useState } from "react";
import circle from "../Images/circle.png";
import learnimg from '../Images/LEARN.png'
import "./CoursePage.css";
import axios from "axios";
import Form from "./Form";
class CoursePage extends React.Component {
  state = {
    details: [],
    showModal: false,
  };
  handleModalClose = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
    let data;
    axios
      .get("https://www.emergiogames.com/emergio/course", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        data = res.data;
        this.setState({
          details: data,
        });
      })
      .catch((err) => {});
  }
  render() {
    const { showModal } = this.state;
    return (
      <>
        <main className="bg-black pt-[6rem] md:pt-0">
          <section>
            <div className="relative">
              <img
                className="w-[320px] h-[200px] left-[0%] ml-[-10.2rem]"
                src={circle}
                alt=""
              />
            </div>
            <div className="w-[100%] mt-[-4rem]  flex justify-center ">
              <div className="flex flex-wrap justify-center sm:justify-between  w-[88%] mt-[-12rem]  sm:mt-[2rem]  ">
                <div className="flex flex-col flex-wrap w-[90%] lg:w-[50%] justify-center">
                  <h2 className="text-white text-[39px] md:text-[54px] font-[700]">
                    Keep Learning With Emergio
                  </h2>
                  <p className="text-white text-[17px] mt-2 textsmall">
                    Have you ever craved a skill that could unlock exciting
                    possibilities? Our courses are your adventure map to a world
                    of knowledge and growth. Imagine turning your curiosity into
                    mastery, like brewing the perfect cup of coffee, coding your
                    own video game, or composing a captivating song. These
                    courses are more than just lessons; they're springboards to
                    launch your passions and ignite your potential. So, ditch
                    the sidelines and become the hero of your own story. It all
                    starts with a single click!
                  </p>
                </div>
                <div className="text-white flex justify-center items-center">
                  <img  src={learnimg} className="sm:w-[500px] sm:h-[500px]" alt="" />
                </div>
              </div>
            </div>
            {/* course cards */}
            <div >
              <div className="cards_div_custom">
                {this.state.details.map((detail, id) => (
                  <div className="card_custom_outer" key={id}>
                    <div className="custom_class">
                      <img
                        className="crs-img  md:w-[100%] sm:w-[200px] h-[200px] mr-6 relative transition-transform duration-300 transform hover:scale-1.5"
                        src={`https://www.emergiogames.com${detail.image}`}
                        alt=""
                      />
                      <img
                        className="sm:w-[80px] w-[60px] h-[60px] block mt-[-2.2em] mx-auto sm:h-[80px] z-10 border-2 border-[#21C8ED] rounded-full"
                        src={`https://www.emergiogames.com${detail.icon}`}
                        alt=""
                      />
                    </div>
                    <div className="mycontent_custom">
                      <h4 className="text-white text-[20px] font-[700] pt-[3rem]  ">
                        {detail.name}
                      </h4>
                      <p className="textsmall t-3 mb-5 h-full text-ellipsis overflow-hidden text-start sm:text-[12px]">
                        {detail.description}
                      </p>
                    </div>
                    <div className="a_course">
                      <a
                        href={`https://www.emergiogames.com${detail.syllabus}`}
                        className="vdo-icon mt-[-40px] hover:bg-[#21C8ED] bg-transparent w-[140px] h-[50px]  text-[13px] font-[700] border-[0.568px] border-white  hover:bg- transition-colors duration-500 text-white  py-2 px-4"
                      >
                        SYLLABUS
                      </a>
                    </div>
                  </div>
                ))}
                {showModal && <Form onClose={this.handleModalClose} />}
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
export default CoursePage;
